
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class extends Vue {
  @Prop()
  value: string;

  get url() {
    return `https://boxs.hk/exchange/${this.value}`
  }
}
