

import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";

@Component
export default class extends Vue {
    @Prop()
    value : string

    image : string = null;

    created () {
        if(process.server && this.value) {
            const code = require('qrcode').create(this.value, {});
            const svg = require('qrcode/lib/renderer/svg-tag').render(code, {
                width: 512,
                color: {
                    light: '#ffffff',
                    dark: '#000000'
                },
            });
            this.image = 'data:image/svg+xml;utf8,' + encodeURIComponent(svg);
        }
    }

    mounted() {
        this.loadImage();
    }

    @Watch('value')
    async loadImage() {
        try {
            if(!this.value) {
                this.image = '';
                return;
            }
            const qrcode = (await import('qrcode'));
            this.image = await qrcode.toDataURL(this.value, {
                width: 512,
                color: {
                    light: '#ffffff',
                    dark: '#000000'
                }
            })
        } catch(e) {
            console.log(e, e.stack);
        }
    }
}

