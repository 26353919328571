export { default as AsyncPopulate } from '../../dep/feathers-client/components/AsyncPopulate.vue'
export { default as BackToTopBtn } from '../../dep/feathers-client/components/BackToTopBtn.vue'
export { default as BoxsAppContainer } from '../../dep/feathers-client/components/BoxsAppContainer.vue'
export { default as BoxsProfileBtn } from '../../dep/feathers-client/components/BoxsProfileBtn.vue'
export { default as DialogHost } from '../../dep/feathers-client/components/DialogHost.vue'
export { default as DropDownMenu } from '../../dep/feathers-client/components/DropDownMenu.vue'
export { default as FullscreenHost } from '../../dep/feathers-client/components/FullscreenHost.vue'
export { default as HCard } from '../../dep/feathers-client/components/HCard.vue'
export { default as IdleDialog } from '../../dep/feathers-client/components/IdleDialog.vue'
export { default as ItemPicker } from '../../dep/feathers-client/components/ItemPicker.vue'
export { default as ItemPickerNew } from '../../dep/feathers-client/components/ItemPickerNew.vue'
export { default as LazyRender } from '../../dep/feathers-client/components/LazyRender.vue'
export { default as ListTree } from '../../dep/feathers-client/components/ListTree.vue'
export { default as MenuList } from '../../dep/feathers-client/components/MenuList.vue'
export { default as MenuListButton } from '../../dep/feathers-client/components/MenuListButton.vue'
export { default as MenuListCheckbox } from '../../dep/feathers-client/components/MenuListCheckbox.vue'
export { default as MenuListInput } from '../../dep/feathers-client/components/MenuListInput.vue'
export { default as MenuListItem } from '../../dep/feathers-client/components/MenuListItem.vue'
export { default as MenuListNum } from '../../dep/feathers-client/components/MenuListNum.vue'
export { default as MenuListNumOnClick } from '../../dep/feathers-client/components/MenuListNumOnClick.vue'
export { default as MenuListPicker } from '../../dep/feathers-client/components/MenuListPicker.vue'
export { default as MenuListPickerDropdown } from '../../dep/feathers-client/components/MenuListPickerDropdown.vue'
export { default as MenuListPickerItem } from '../../dep/feathers-client/components/MenuListPickerItem.vue'
export { default as MenuListSwitch } from '../../dep/feathers-client/components/MenuListSwitch.vue'
export { default as MultiLangText } from '../../dep/feathers-client/components/MultiLangText.vue'
export { default as NestedRouter } from '../../dep/feathers-client/components/NestedRouter.vue'
export { default as NumPad } from '../../dep/feathers-client/components/NumPad.vue'
export { default as NumPadNew } from '../../dep/feathers-client/components/NumPadNew.vue'
export { default as NumPadSimply } from '../../dep/feathers-client/components/NumPadSimply.vue'
export { default as ResizeSensor } from '../../dep/feathers-client/components/ResizeSensor.vue'
export { default as SlotRender } from '../../dep/feathers-client/components/SlotRender.vue'
export { default as StaticRender } from '../../dep/feathers-client/components/StaticRender.vue'
export { default as StatusHost } from '../../dep/feathers-client/components/StatusHost.vue'
export { default as SwipeAction } from '../../dep/feathers-client/components/SwipeAction.vue'
export { default as TabPicker } from '../../dep/feathers-client/components/TabPicker.vue'
export { default as TeleportMenu } from '../../dep/feathers-client/components/TeleportMenu.vue'
export { default as Highlighter } from '../../dep/feathers-client/components/highlighter.vue'
export { default as BoxsNavBarItem } from '../../dep/feathers-client/components/BoxsNavBar/Item.vue'
export { default as BoxsNavBar } from '../../dep/feathers-client/components/BoxsNavBar/index.vue'
export { default as BoxsNavBarNewNavItem } from '../../dep/feathers-client/components/BoxsNavBar/newNavItem.vue'
export { default as AddApplication } from '../../components/AddApplication.vue'
export { default as BBtn } from '../../components/BBtn.vue'
export { default as BoxsConnect } from '../../components/BoxsConnect.vue'
export { default as ExchangeQrCode } from '../../components/ExchangeQrCode.vue'
export { default as FileDownloader } from '../../components/FileDownloader.vue'
export { default as FilePicker } from '../../components/FilePicker.vue'
export { default as ImagePicker } from '../../components/ImagePicker.vue'
export { default as JwkEditor } from '../../components/JwkEditor.vue'
export { default as MediaLibrary } from '../../components/MediaLibrary.vue'
export { default as ObjectPicker } from '../../components/ObjectPicker.vue'
export { default as PosDevice } from '../../components/PosDevice.vue'
export { default as PrinterConf } from '../../components/PrinterConf.vue'
export { default as QrCode } from '../../components/QrCode.vue'
export { default as TextDialog } from '../../components/TextDialog.vue'
export { default as UberEatsManager } from '../../components/UberEatsManager.vue'
export { default as UberEatsStore } from '../../components/UberEatsStore.vue'
export { default as BoxsNavTitle } from '../../components/boxs/NavTitle.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
