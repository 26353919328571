

import { Component, Vue, Prop } from "@feathers-client"

@Component
export default class TextDialog extends Vue {
  @Prop()
  text: string
}

