import locale453337ca from '../../locales/zh-hk.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"zh-hk"},
  vueI18nLoader: false,
  locales: [{"code":"zh-hk","iso":"zh-hk","file":"zh-hk.json","name":"繁體中文"},{"code":"zh-cn","iso":"zh-cn","file":"zh-cn.json","name":"简體中文"},{"code":"en","iso":"en-hk","moment":"en-us","cybersource":"en-us","file":"en.json","name":"English"}],
  defaultLocale: "zh-hk",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "./locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"zh-hk","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: (ctx) => {
          return ctx.$config.baseUrl;
        },
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: false,
  silentFallbackWarn: true,
  normalizedLocales: [{"code":"zh-hk","iso":"zh-hk","file":"zh-hk.json","name":"繁體中文"},{"code":"zh-cn","iso":"zh-cn","file":"zh-cn.json","name":"简體中文"},{"code":"en","iso":"en-hk","moment":"en-us","cybersource":"en-us","file":"en.json","name":"English"}],
  localeCodes: ["zh-hk","zh-cn","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "z",
  3: "h",
  4: "-",
  5: "h",
  6: "k",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "l",
  19: "o",
  20: "c",
  21: "a",
  22: "l",
  23: "e",
  24: "s",
  25: "/",
  26: "z",
  27: "h",
  28: "-",
  29: "h",
  30: "k",
  31: ".",
  32: "j",
  33: "s",
  34: "o",
  35: "n",
  36: "\"",
  37: ",",
  38: "\"",
  39: "z",
  40: "h",
  41: "-",
  42: "c",
  43: "n",
  44: ".",
  45: "j",
  46: "s",
  47: "o",
  48: "n",
  49: "\"",
  50: ":",
  51: "\"",
  52: ".",
  53: ".",
  54: "/",
  55: "l",
  56: "o",
  57: "c",
  58: "a",
  59: "l",
  60: "e",
  61: "s",
  62: "/",
  63: "z",
  64: "h",
  65: "-",
  66: "c",
  67: "n",
  68: ".",
  69: "j",
  70: "s",
  71: "o",
  72: "n",
  73: "\"",
  74: ",",
  75: "\"",
  76: "e",
  77: "n",
  78: ".",
  79: "j",
  80: "s",
  81: "o",
  82: "n",
  83: "\"",
  84: ":",
  85: "\"",
  86: ".",
  87: ".",
  88: "/",
  89: "l",
  90: "o",
  91: "c",
  92: "a",
  93: "l",
  94: "e",
  95: "s",
  96: "/",
  97: "e",
  98: "n",
  99: ".",
  100: "j",
  101: "s",
  102: "o",
  103: "n",
  104: "\"",
  105: "}",
}

export const localeMessages = {
  'zh-hk.json': () => Promise.resolve(locale453337ca),
  'zh-cn.json': () => import('../../locales/zh-cn.json' /* webpackChunkName: "lang-zh-cn.json" */),
  'en.json': () => import('../../locales/en.json' /* webpackChunkName: "lang-en.json" */),
}

let _context;

  if(module.hot && process.client) {
    module.hot.accept('../../locales/zh-hk.json', function () {
      localeMessages['zh-hk.json'] = () => import('../../locales/zh-hk.json');
      if(_context && _context.i18n.loadedLanguages.includes('zh-hk')) {
        const idx = _context.i18n.loadedLanguages.indexOf('zh-hk');
        _context.i18n.loadedLanguages.splice(idx, 1);
        import('./plugin.utils').then((v) => v.loadLanguageAsync(_context, 'zh-hk'));
      }
    });
  }

  if(module.hot && process.client) {
    module.hot.accept('../../locales/zh-cn.json', function () {
      localeMessages['zh-cn.json'] = () => import('../../locales/zh-cn.json');
      if(_context && _context.i18n.loadedLanguages.includes('zh-cn')) {
        const idx = _context.i18n.loadedLanguages.indexOf('zh-cn');
        _context.i18n.loadedLanguages.splice(idx, 1);
        import('./plugin.utils').then((v) => v.loadLanguageAsync(_context, 'zh-cn'));
      }
    });
  }

  if(module.hot && process.client) {
    module.hot.accept('../../locales/en.json', function () {
      localeMessages['en.json'] = () => import('../../locales/en.json');
      if(_context && _context.i18n.loadedLanguages.includes('en')) {
        const idx = _context.i18n.loadedLanguages.indexOf('en');
        _context.i18n.loadedLanguages.splice(idx, 1);
        import('./plugin.utils').then((v) => v.loadLanguageAsync(_context, 'en'));
      }
    });
  }

export function setHmrContext(context) {
  _context = context;
}
