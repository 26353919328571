<template>
<span>
    <v-btn color="primary" v-if="!info || !info.processing" v-t="name" @click.prevent.stop="pick"/>
    <v-btn color="primary" v-else disabled>
        <v-progress-circular :value="minfo.progress * 100" color="blue"></v-progress-circular>
    </v-btn>
</span>
</template>

<script>

import uuid from 'uuid/v4'
import _ from 'lodash'
import path from 'path'

export default {
    props: {'value': {}, 'mode': {}, 'name': {}, 'dir': { default: ''}, attachment: { type: Boolean, default: true} },

    data() {
        return {
            minfo: null,
        }
    },

    computed: {
        info() {
            return this.minfo || this.vinfo;
        },

        vinfo() {
            if(!this.value) return null;
            return _.assign({
                success: true,
                complete: true,
                processing: false,
                error: null,
                progress: 1
            }, this.attachment ? this.value : {});
        }
    },

    methods: {
        async pick() {
            const file = document.createElement('input');
            file.style.display = 'none';
            file.type = 'file';
            file.accept = this.type;
            document.body.append(file);
            file.click();
            await new Promise((resolve) => file.onchange = resolve);
            if (file.files.length == 0) return;
            const mfile = file.files[0];
            let upload = mfile;

            if(!this.attachment) {
                const reader = new FileReader();
                reader.readAsArrayBuffer(mfile);
                const arr = await new Promise((resolve) => reader.onload = resolve);
                const buf = new Buffer(arr.target.result);
                this.$emit('input', buf);
                return;
            }

            const ext = path.extname(mfile.name);

            if(ext === '.ply' && this.mode === 'mesh') {
                const reader = new FileReader();
                reader.readAsArrayBuffer(mfile);
                const arr = await new Promise((resolve) => reader.onload = resolve);
                const buf = new Buffer(arr.target.result);
                const b64 = buf.toString('base64');
                const b64b = new Buffer(b64);
                upload = new Blob([b64b], { type: 'text/plain' });
            }

            var data = new FormData();
            data.append('file', upload, mfile.name);

            const info = {
                name: mfile.name,
                size: mfile.size,
                mime: mfile.type,
                thumb: null,
                id: uuid(),
                success: false,
                complete: false,
                processing: true,
                error: null,
                progress: 0
            };
            this.minfo = info;

            try {
                const response = await this.$feathers.post(`attachments/upload/${this.dir}`, data, {
                    onUploadProgress: (progressEvent) => {
                        info.progress = progressEvent.loaded / progressEvent.total;
                    }
                });

                const rinfo = (response.data || {}).info || {};
                _.assign(info, rinfo);
                info.success = true;
                info.complete = true;
                info.progress = 1;
                info.processing = false;
            } catch (e) {
                info.error = e.message;
                info.complete = true;
                info.processing = false;
            }

            this.$emit('input', info);
        }
    }
}

</script>