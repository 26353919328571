
import { Component, FindType, Prop, Vue } from "@feathers-client";
import JsonEditor from "pos-printer-template/JsonEditor.vue";

@Component({
  components: {
    JsonEditor,
  },
})
export default class PrinterConf extends Vue {
  @Prop()
  item: FindType<"printerConfs">;

  get inputValue() {
    return this.item?.printerOpts || {};
  }

  set inputValue(v) {
    Vue.set(this.item, "printerOpts", v || {});
  }

  applyESCPOSDefault() {
    Vue.set(this.item, "printerOpts", {

      nativeQR: true,
      usePrintModeOnly: false,
      pageMode: false,
      lineImage: true,
      doubleVDensity: true,
      bitmapImage: true,
      supportMultiColor: false,
      nvImage: "none",
      codePage: "gbk",
      singleCodePage: -1,
      multiCodePage: -1,
      lineWidth: 48,
      clineWidth: 60,
      lineImageHeight: 0,
      fontWidth: 12,
      fontHeight: 12,

      statusCheck: false,
      asb: false,
      escpos: "escpos",
      cut: true,
      cutPreFeed: 10,
      cutPostFeed: 1,
      feedValue: 30,
      clearBefore: true,
    })
  }
}
