

import { Component, Vue, Prop } from "@feathers-client"
import { downloadFile } from "domore-table/exportExcel";

@Component
export default class FileDownloader extends Vue {
  @Prop()
  value: string | Buffer

  @Prop()
  path: string

  @Prop()
  type: string

  async download() {
    await downloadFile([
      typeof this.value === 'string' ? Buffer.from(this.value, 'base64') : this.value
    ], this.path)
  }

  async open() {
    const buf = typeof this.value === 'string' ? Buffer.from(this.value, 'base64') : this.value;
    const txt = buf.toString();
    await this.$openDialog(import("./TextDialog.vue"), {
      text: txt
    }, {
      contentClass: 'editor-dialog',
      maxWidth: '80vw',
    })
  }
}

