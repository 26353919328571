
import { Component, Vue, Ref, Watch, FindType, Prop } from "@feathers-client";
import EditorObjectPickerList from "@schemaEditor/EditorObjectPickerList.vue";
@Component({
  components: {
    EditorObjectPickerList,
  },
})
export default class UberEatsStore extends Vue {
  @Prop()
  item: FindType<"uberEats/stores">[number];

  @Prop()
  account: FindType<"uberEats/accounts">[number];

  uberEatsMerchant: FindType<"uberEatsMerchants"> = null;

  async mounted() {
    this.uberEatsMerchant = (
      await this.$feathers.service("uberEatsMerchants").find({
        query: {
          storeIds: this.item.store_id,
          $paginate: false,
        },
        paginate: false,
      })
    )[0];
  }

  async setMerchant(merchant: FindType<"uberEatsMerchants">) {
    await this.$feathers.service("uberEatsMerchants").patch(
      null,
      {
        $pull: {
          storeIds: this.item.store_id,
        },
      },
      {
        query: {
          storeIds: this.item.store_id,
        },
      },
    );

    if (merchant) {
      this.uberEatsMerchant = await this.$feathers.service("uberEatsMerchants").patch(merchant._id, {
        $push: {
          storeIds: this.item.store_id,
        },
      });
    } else {
      this.uberEatsMerchant = null;
    }
  }

  async deactivateIntegration() {
    const resp = await this.$feathers.service("uberEats/stores/integrations").remove(this.item.store_id, {
      query: {
        clientId: this.account.clientId,
      },
    });
    for (let [key, value] of Object.entries(resp)) {
      Vue.set(this.item, key, value);
    }
  }
}
