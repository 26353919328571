import { Context } from "@nuxt/types";
import helper from "@feathers-client/helper";
import _ from "lodash";

export default helper("jwkEditor", (ctx: Context) => {
  if (process.server) return;
  ctx.app.$schemas.registerType("jwk", {
    component: () => import("~/components/JwkEditor.vue"),
  });

  ctx.app.$schemas.registerType("jsonobj", {
    component: () => import("~/dep/pos-printer-template/JsonEditor.vue"),
    props: {
      "editor-size": 12,
      isObject: true,
    },
  });

  ctx.app.$schemas.registerType("jsonstr", {
    component: () => import("~/dep/pos-printer-template/JsonEditor.vue"),
    props: {
      "editor-size": 12,
      isObject: false,
    },
  });

  ctx.app.$schemas.registerType("translateId", {
    format(self, v: string, item) {
      return self.$t(v, (item as any).data || {}) as string;
    },
  });
});
