
import { Component, Vue, Ref, Watch, FindType, Prop } from "@feathers-client";
@Component({
  components: {},
})
export default class UberEatsManager extends Vue {
  @Prop()
  item: FindType<"uberEats/accounts">[number];

  stores: FindType<"uberEats/stores"> = null;

  async loginProvision() {
    const { url } = await this.$feathers.service("uberEats/login").create({
      clientId: this.item.clientId,
    });
    window.open(url, "_blank");
  }

  async logoutProvision() {
    await this.$feathers.service("uberEats/login").remove(this.item.clientId);
  }

  async getStores() {
    this.stores = await this.$feathers.service("uberEats/stores").find({
      query: {
        clientId: this.item.clientId,
      },
    });
  }
}
