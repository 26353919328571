import { render, staticRenderFns } from "./default.vue?vue&type=template&id=314f53c6&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=314f53c6&lang=css"
import style1 from "./default.vue?vue&type=style&index=1&id=314f53c6&scoped=true&lang=css"
import style2 from "./default.vue?vue&type=style&index=2&id=314f53c6&lang=css"
import style3 from "./default.vue?vue&type=style&index=3&id=314f53c6&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314f53c6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VBtn,VCard,VCardText,VFadeTransition,VIcon,VList,VListItem,VListItemTitle,VMenu,VProgressCircular,VSpacer,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/jason/p/boxs/boxs-cloud/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('314f53c6')) {
      api.createRecord('314f53c6', component.options)
    } else {
      api.reload('314f53c6', component.options)
    }
    module.hot.accept("./default.vue?vue&type=template&id=314f53c6&scoped=true", function () {
      api.rerender('314f53c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "layouts/default.vue"
export default component.exports