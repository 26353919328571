
import { Component, Prop, Vue, Watch, mixins, FindType, Ref } from "@feathers-client";

@Component
export default class extends Vue {
  @Prop()
  item: FindType<"posDevices">;

  remote() {
    window.open(`/posDevices/remote/${this.item._id}`, "_blank");
  }
}
