import type * as monacoEditor from "monaco-editor/esm/vs/editor/editor.api";
import { Component, Prop, Ref, Vue, Watch } from "@feathers-client";
import { init, __getMonacoInstance } from "./EditorLoader";

export type Nullable<T> = T | null;
export type MonacoEditor = typeof monacoEditor;

export interface VueMonacoEditorEmitsOptions {
  "update:value": (value: string | undefined) => void;
  beforeMount: (monaco: MonacoEditor) => void;
  mount: (editor: monacoEditor.editor.IStandaloneCodeEditor, monaco: MonacoEditor) => void;
  change: (value: string | undefined, event: monacoEditor.editor.IModelContentChangedEvent) => void;
  [key: string]: any;
}

export interface EditorProps {
  defaultValue?: string;
  defaultPath?: string;
  defaultLanguage?: string;
  value?: string;
  language?: string;
  path?: string;

  /* === */

  theme: "vs" | string;
  line?: number;
  options: monacoEditor.editor.IStandaloneEditorConstructionOptions;
  overrideServices: monacoEditor.editor.IEditorOverrideServices;
  saveViewState: boolean;

  /* === */

  width: number | string;
  height: number | string;
  className?: string;
}

const loadingStyle = {
  display: "flex",
  height: "100%",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
};

@Component
export class VueMonacoEditor extends Vue {
  @Prop()
  value: string;

  @Prop()
  defaultValue?: string;

  @Prop()
  defaultPath?: string;

  @Prop()
  defaultLanguage?: string;

  @Prop()
  language?: string;

  @Prop()
  path?: string;

  @Prop({ type: String, default: "vs" })
  theme?: string;

  @Prop({ type: Number })
  line?: number;

  @Prop({ type: Object, default: () => ({}) })
  options?: monacoEditor.editor.IStandaloneEditorConstructionOptions;

  @Prop({ type: Object, default: () => ({}) })
  overrideServices?: monacoEditor.editor.IEditorOverrideServices;

  @Prop({ type: Boolean, default: true })
  saveViewState?: boolean;

  @Prop({ type: [Number, String], default: "100%" })
  width?: number | string;

  @Prop({ type: [Number, String], default: "100%" })
  height?: number | string;

  @Prop({ type: String })
  className?: string;

  viewStates: Map<string | undefined, Nullable<monacoEditor.editor.ICodeEditorViewState>>;

  monacoRef: MonacoEditor;
  editorRef: monacoEditor.editor.IStandaloneCodeEditor;

  isEditorReady = false;

  @Ref()
  containerRef: HTMLElement;

  beforeMount() {
    this.$root.$on("finishEdit", this.finishEdit);
  }

  async mounted() {
    this.viewStates = new Map<string | undefined, Nullable<monacoEditor.editor.ICodeEditorViewState>>();
    this.monacoRef = __getMonacoInstance();
    if (!this.monacoRef) {
      this.monacoRef = await init();
    } else {
      await new Promise(resolve => setTimeout(resolve, 500));
    }
    this.isEditorReady = true;
    this.initEditor();
  }

  beforeDestroy() {
    this.$root.$off("finishEdit", this.finishEdit);
    this.finishEdit();
  }

  finishEdit() {
    if (this.editorRef) {
      this.editorRef.getModel()?.dispose();
      this.editorRef.dispose();
      this.editorRef = null;
    }
  }

  @Watch("isEditorReady")
  async initEditor() {
    await Vue.nextTick();
    if (this.containerRef && this.monacoRef) {
      this.createEditor();
    }
  }

  createEditor() {
    if (!this.containerRef || !this.monacoRef || this.editorRef) {
      return;
    }

    this.$emit("beforeMount", this.monacoRef);

    const autoCreatedModelPath = this.path || this.defaultPath;
    const defaultModel = getOrCreateModel(
      this.monacoRef,
      this.value || this.defaultValue || "",
      this.language || this.defaultLanguage || "",
      autoCreatedModelPath || "",
    );

    this.editorRef = this.monacoRef.editor.create(
      this.containerRef,
      {
        model: defaultModel,
        theme: this.theme,
        automaticLayout: true,
        autoIndent: "brackets",
        formatOnPaste: true,
        formatOnType: true,
        tabSize: 2,
        inlineSuggest: {
          enabled: false,
        },
        "semanticHighlighting.enabled": true,
        ...this.options,
      },
      this.overrideServices,
    );

    // Support for semantic highlighting
    const t = (this.editorRef as any)._themeService._theme;
    t.getTokenStyleMetadata = (type: string, modifiers: string[], _language: string) => {
      const _readonly = modifiers.includes("readonly");
      switch (type) {
        case "function":
        case "method":
          return { foreground: 12 };
        case "class":
          return { foreground: 11 };
        case "variable":
        case "property":
          return { foreground: _readonly ? 21 : 9 };
        default:
          return { foreground: 0 };
      }
    };

    (window as any).monacoVolar.loadGrammars(this.monacoRef, this.editorRef);

    this.editorRef?.onDidChangeModelContent?.(event => {
      const value = this.editorRef.getValue();
      if (value !== this.value) {
        // props['onUpdate:value']?.(value)
        this.$emit("input", value);
        // props.onChange?.(value, event)
        this.$emit("change", value, event);
      }
    });

    // reason for undefined check: https://github.com/suren-atoyan/monaco-react/pull/188
    if (this.editorRef && typeof this.line !== "undefined") {
      this.editorRef.revealLine(this.line!);
    }

    // editor mount
    // props.onMount?.(editorRef.value, monacoRef.value)
    this.$emit("mount", this.editorRef, this.monacoRef);
  }

  render(h) {
    return h(
      "div",
      {
        style: this.wrapperStyle as any,
      },
      [
        !this.isEditorReady &&
          h(
            "div",
            {
              style: loadingStyle,
            },
            this.$slots.default ? defaultSlotHelper(this.$slots.default) : "loading...",
          ),
        h("div", {
          ref: "containerRef",
          key: "monaco_editor_container",
          style: this.containerStyle,
          class: this.className,
        }),
      ],
    );
  }

  get wrapperStyle() {
    const { width, height } = this;
    return {
      ...styles.wrapper,
      width,
      height,
    };
  }

  get containerStyle() {
    return {
      ...styles.fullWidth,
      ...(!this.isEditorReady && styles.hide),
    };
  }

  @Watch("path")
  onPath(newPath, oldPath) {
    const model = getOrCreateModel(
      this.monacoRef,
      this.value || this.defaultValue || "",
      this.language || this.defaultLanguage || "",
      newPath || this.defaultPath || "",
    );

    if (model !== this.editorRef.getModel()) {
      this.saveViewState && this.viewStates.set(oldPath, this.editorRef.saveViewState());
      this.editorRef.setModel(model);
      this.saveViewState && this.editorRef.restoreViewState(this.viewStates.get(newPath)!);
    }
  }

  @Watch("value")
  onValue(newValue, oldValue) {
    if (this.editorRef && newValue !== oldValue && newValue !== this.editorRef.getValue()) {
      this.editorRef.setValue(newValue);
    }
  }

  @Watch("options", { deep: true })
  onOptions(newOptions, oldOptions) {
    if (this.editorRef) {
      this.editorRef.updateOptions(newOptions);
    }
  }

  @Watch("theme")
  onTheme(newTheme, oldTheme) {
    if (this.monacoRef) {
      this.monacoRef.editor.setTheme(newTheme);
    }
  }

  @Watch("language")
  onLanguage(newLanguage, oldLanguage) {
    if (this.isEditorReady) {
      this.monacoRef.editor.setModelLanguage(this.editorRef.getModel()!, newLanguage);
    }
  }

  @Watch("line")
  onLine(newLine, oldLine) {
    if (this.editorRef && newLine !== oldLine) {
      this.editorRef.revealLine(newLine);
    }
  }
}

const styles = {
  wrapper: {
    display: "flex",
    position: "relative",
    textAlign: "initial",
  },
  fullWidth: {
    width: "100%",
  },
  hide: {
    display: "none",
  },
};

export function defaultSlotHelper(defaultSlots: any) {
  return typeof defaultSlots == "function" ? defaultSlots() : defaultSlots;
}

export function getOrCreateModel(monaco: MonacoEditor, value: string, language?: string, path?: string) {
  return getModel(monaco, path!) || createModel(monaco, value, language, path);
}

export function getModel(monaco: MonacoEditor, path: string) {
  return monaco.editor.getModel(createModelUri(monaco, path));
}

function createModel(monaco: MonacoEditor, value: string, language?: string, path?: string) {
  return monaco.editor.createModel(value, language, path ? createModelUri(monaco, path) : undefined);
}

function createModelUri(monaco: MonacoEditor, path: string) {
  return monaco.Uri.parse(path);
}
