

import EditorTextField from '@schemaEditor/EditorTextField.vue';
import { Component, Prop, Vue, mixins } from '@feathers-client';
import Dialog from '@feathers-client/mixins/Dialog';

@Component({
  components: {
    EditorTextField,
  }
})
export default class AddApplication extends mixins(Dialog) {

  name = '';
  public: string = '';
  private: string = '';
  keyHash = '';
  keyConfig: string = '';

  async mounted() {
    const key = await crypto.subtle.generateKey({
      name: "ECDSA",
      namedCurve: "P-256",
    }, true, ['sign', 'verify']);

    const jwk = await crypto.subtle.exportKey("jwk", key.publicKey);
    this.public = JSON.stringify(jwk);

    const sortedPub = `{"crv":"${jwk.crv}","kty":"EC","x":"${jwk.x}","y":"${jwk.y}"}`;
        
    const hash = await crypto.subtle.digest({ name: 'SHA-256' }, Buffer.from(sortedPub));
    this.keyHash = Buffer.from(hash).toString("base64");

    const p = await crypto.subtle.exportKey("jwk", key.privateKey);
    this.private = JSON.stringify(p);
    this.keyConfig = JSON.stringify({
      private: p,
      kid: this.keyHash,
    })
  }

  async save() {
    await this.$feathers.service("applications").create({
      name: this.name,
      kid: this.keyHash,
      publicKey: JSON.parse(this.public),
    })
    this.modalResult(true);
  }

}

