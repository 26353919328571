import simpleLoadScript from "simple-load-script";
import type * as monacoEditor from "monaco-editor/esm/vs/editor/editor.api";

let monaco: typeof monacoEditor;

export function __getMonacoInstance() {
  return monaco;
}

let initPromise: ReturnType<typeof initInner>;

export function init() {
  return initPromise || (initPromise = initInner());
}
declare const volar: any;
const basePath = "https://cdn.jsdelivr.net/npm/monaco-editor@0.45.0/min/vs";
const baseWorkerPath = "https://cdn.jsdelivr.net/npm/monaco-editor@0.45.0/min/";

export async function initInner() {
  window.process = process || ({} as any);

  await simpleLoadScript(basePath + "/loader.js");
  const js = `
    import { loadGrammars } from "https://esm.sh/monaco-volar@0.4.0";
    import * as volar from "https://esm.sh/@volar/monaco@1.7.9";
    import * as onigasm from "https://esm.sh/onigasm@2.2.5";
    window.monacoVolar = { loadGrammars };
    window.volar = volar;
    window.onigasm = onigasm;
  `;

  const blob = new Blob([js], {
    type: "text/javascript",
  });
  const url = URL.createObjectURL(blob);
  await simpleLoadScript(url, {
    attrs: {
      type: "module",
    },
  });

  const require: any = window.require;

  await new Promise<void>((resolve, reject) => {
    require.config({
      paths: {
        vs: basePath,
      },
    });
    require(["vs/editor/editor.main"], function (_monaco) {
      monaco = _monaco;
      resolve();
    }, function (err) {
      reject(err);
    });
  });

  monaco.languages.register({ id: "vue", extensions: [".vue"] });
  monaco.languages.onLanguage("vue", setup);

  monaco.languages.onLanguage("javascript", setup);
  monaco.languages.onLanguage("typescript", setup);
  monaco.languages.onLanguage("javascriptreact", setup);
  monaco.languages.onLanguage("typescriptreact", setup);
  monaco.languages.onLanguage("json", setup);

  let initialized = false;

  async function setup() {
    if (initialized) {
      return;
    }
    initialized = true;

    (self as any).MonacoEnvironment ??= {};
    (self as any).MonacoEnvironment.getWorker ??= (x, label) => {
      return new Worker(
        getWorkerBootstrapUrl(
          "https://cdn.jsdelivr.net/npm/monaco-editor@0.45.0/min/vs/base/worker/workerMain.js",
          label,
        ),
      );
    };

    const getWorker = (self as any).MonacoEnvironment.getWorker;

    (self as any).MonacoEnvironment.getWorker = (_: any, label: string) => {
      if (label === "vue") {
        return new Worker(
          URL.createObjectURL(
            new Blob(
              [
`
self.process = {
  env: {},
  platform: "linux",
  type: "renderer",
  versions: {
    electron: "x"
  }
};
importScripts("https://cdn.jsdelivr.net/npm/monaco-volar@0.4.0/dist/worker/vue.worker.js");

parserOptions.decodeEntities = (rawText) => rawText.replace(decodeRE, (_, p1) => decodeMap[p1]);
const _getDefaultCompilerOptions = typescriptExports.getDefaultCompilerOptions;
typescriptExports = {
  ...typescriptExports,
  getDefaultCompilerOptions: () => {
    return {
      ..._getDefaultCompilerOptions(),
      resolveJsonModule: true,
      allowSyntheticDefaultImports: true
    }
  }
}
`,
              ],
              { type: "text/javascript" },
            ),
          ),
          {
            // type: "module",
          }
        );
      }
      return getWorker();
    };

    const worker = monaco.editor.createWebWorker({
      moduleId: "vs/language/vue/vueWorker",
      label: "vue",
      createData: {},
    });
    const languageId = ["vue", "javascript", "typescript", "javascriptreact", "typescriptreact", "json"];
    const getSyncUris = () => monaco.editor.getModels().map(model => model.uri);
    volar.editor.activateMarkers(worker, languageId, "vue", getSyncUris, monaco.editor);
    volar.editor.activateAutoInsertion(worker, languageId, getSyncUris, monaco.editor);
    await worker.getProxy();
    await volar.languages.registerProvides(worker, languageId, getSyncUris, monaco.languages);
  }

  await (window as any).onigasm.loadWASM("https://cdn.jsdelivr.net/npm/onigasm@2.2.5/lib/onigasm.wasm");

  return monaco;
}

export function getWorkerBootstrapUrl(scriptPath: string, label: string): string {
  if (
    /^((http:)|(https:)|(file:))/.test(scriptPath) &&
    scriptPath.substring(0, globalThis.origin.length) !== globalThis.origin
  ) {
    // this is the cross-origin case
    // i.e. the webpage is running at a different origin than where the scripts are loaded from
    const js = `/*${label}*/globalThis.MonacoEnvironment={baseUrl: '${baseWorkerPath}'};const ttPolicy = globalThis.trustedTypes?.createPolicy('defaultWorkerFactory', { createScriptURL: value => value });importScripts(ttPolicy?.createScriptURL('${scriptPath}') ?? '${scriptPath}');/*${label}*/`;
    const blob = new Blob([js], { type: "application/javascript" });
    return URL.createObjectURL(blob);
  }

  const start = scriptPath.lastIndexOf("?");
  const end = scriptPath.lastIndexOf("#", start);
  const params =
    start > 0 ? new URLSearchParams(scriptPath.substring(start + 1, ~end ? end : undefined)) : new URLSearchParams();

  const search = params.toString();

  if (!search) {
    return `${scriptPath}#${label}`;
  } else {
    return `${scriptPath}?${params.toString()}#${label}`;
  }
}
