import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _73ed052c = () => interopDefault(import('../pages/admins/index.vue' /* webpackChunkName: "pages/admins/index" */))
const _0971ba48 = () => interopDefault(import('../pages/applications.vue' /* webpackChunkName: "pages/applications" */))
const _3b1666ea = () => interopDefault(import('../pages/bluetoothTest.vue' /* webpackChunkName: "pages/bluetoothTest" */))
const _4bd989d4 = () => interopDefault(import('../pages/boxsConnects.vue' /* webpackChunkName: "pages/boxsConnects" */))
const _34416dd4 = () => interopDefault(import('../pages/castTest.vue' /* webpackChunkName: "pages/castTest" */))
const _9f6db6e2 = () => interopDefault(import('../pages/cloudPrintTest.vue' /* webpackChunkName: "pages/cloudPrintTest" */))
const _7a386b82 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _05b98a06 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cabdc6f0 = () => interopDefault(import('../pages/flasher.vue' /* webpackChunkName: "pages/flasher" */))
const _3714f4d2 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _5fa1ecef = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5ae22bd8 = () => interopDefault(import('../pages/octopusAlertTest.vue' /* webpackChunkName: "pages/octopusAlertTest" */))
const _26b156fc = () => interopDefault(import('../pages/octopusTest.vue' /* webpackChunkName: "pages/octopusTest" */))
const _31040717 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _14434070 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _c1d20646 = () => interopDefault(import('../pages/uberEats/index.vue' /* webpackChunkName: "pages/uberEats/index" */))
const _1d591576 = () => interopDefault(import('../pages/profile/changePassword.vue' /* webpackChunkName: "pages/profile/changePassword" */))
const _4c728d9e = () => interopDefault(import('../pages/profile/changeProfile.vue' /* webpackChunkName: "pages/profile/changeProfile" */))
const _0d73e002 = () => interopDefault(import('../pages/posDevices/remote/_id.vue' /* webpackChunkName: "pages/posDevices/remote/_id" */))
const _58d8c58a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admins",
    component: _73ed052c,
    name: "admins___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/admins/index.vue').default
  }, {
    path: "/applications",
    component: _0971ba48,
    name: "applications___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/applications.vue').default
  }, {
    path: "/bluetoothTest",
    component: _3b1666ea,
    name: "bluetoothTest___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/bluetoothTest.vue').default
  }, {
    path: "/boxsConnects",
    component: _4bd989d4,
    name: "boxsConnects___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/boxsConnects.vue').default
  }, {
    path: "/castTest",
    component: _34416dd4,
    name: "castTest___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/castTest.vue').default
  }, {
    path: "/cloudPrintTest",
    component: _9f6db6e2,
    name: "cloudPrintTest___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/cloudPrintTest.vue').default
  }, {
    path: "/dashboard",
    component: _7a386b82,
    name: "dashboard___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dashboard.vue').default
  }, {
    path: "/en",
    component: _05b98a06,
    name: "index___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/index.vue').default
  }, {
    path: "/flasher",
    component: _cabdc6f0,
    name: "flasher___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/flasher.vue').default
  }, {
    path: "/login",
    component: _3714f4d2,
    name: "login___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/login/index.vue').default
  }, {
    path: "/logout",
    component: _5fa1ecef,
    name: "logout___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/logout.vue').default
  }, {
    path: "/octopusAlertTest",
    component: _5ae22bd8,
    name: "octopusAlertTest___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/octopusAlertTest.vue').default
  }, {
    path: "/octopusTest",
    component: _26b156fc,
    name: "octopusTest___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/octopusTest.vue').default
  }, {
    path: "/profile",
    component: _31040717,
    name: "profile___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/index.vue').default
  }, {
    path: "/settings",
    component: _14434070,
    name: "settings___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings.vue').default
  }, {
    path: "/uberEats",
    component: _c1d20646,
    name: "uberEats___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/uberEats/index.vue').default
  }, {
    path: "/zh-cn",
    component: _05b98a06,
    name: "index___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/index.vue').default
  }, {
    path: "/en/admins",
    component: _73ed052c,
    name: "admins___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/admins/index.vue').default
  }, {
    path: "/en/applications",
    component: _0971ba48,
    name: "applications___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/applications.vue').default
  }, {
    path: "/en/bluetoothTest",
    component: _3b1666ea,
    name: "bluetoothTest___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/bluetoothTest.vue').default
  }, {
    path: "/en/boxsConnects",
    component: _4bd989d4,
    name: "boxsConnects___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/boxsConnects.vue').default
  }, {
    path: "/en/castTest",
    component: _34416dd4,
    name: "castTest___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/castTest.vue').default
  }, {
    path: "/en/cloudPrintTest",
    component: _9f6db6e2,
    name: "cloudPrintTest___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/cloudPrintTest.vue').default
  }, {
    path: "/en/dashboard",
    component: _7a386b82,
    name: "dashboard___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dashboard.vue').default
  }, {
    path: "/en/flasher",
    component: _cabdc6f0,
    name: "flasher___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/flasher.vue').default
  }, {
    path: "/en/login",
    component: _3714f4d2,
    name: "login___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/login/index.vue').default
  }, {
    path: "/en/logout",
    component: _5fa1ecef,
    name: "logout___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/logout.vue').default
  }, {
    path: "/en/octopusAlertTest",
    component: _5ae22bd8,
    name: "octopusAlertTest___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/octopusAlertTest.vue').default
  }, {
    path: "/en/octopusTest",
    component: _26b156fc,
    name: "octopusTest___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/octopusTest.vue').default
  }, {
    path: "/en/profile",
    component: _31040717,
    name: "profile___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/index.vue').default
  }, {
    path: "/en/settings",
    component: _14434070,
    name: "settings___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings.vue').default
  }, {
    path: "/en/uberEats",
    component: _c1d20646,
    name: "uberEats___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/uberEats/index.vue').default
  }, {
    path: "/profile/changePassword",
    component: _1d591576,
    name: "profile-changePassword___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changePassword.vue').default
  }, {
    path: "/profile/changeProfile",
    component: _4c728d9e,
    name: "profile-changeProfile___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changeProfile.vue').default
  }, {
    path: "/zh-cn/admins",
    component: _73ed052c,
    name: "admins___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/admins/index.vue').default
  }, {
    path: "/zh-cn/applications",
    component: _0971ba48,
    name: "applications___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/applications.vue').default
  }, {
    path: "/zh-cn/bluetoothTest",
    component: _3b1666ea,
    name: "bluetoothTest___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/bluetoothTest.vue').default
  }, {
    path: "/zh-cn/boxsConnects",
    component: _4bd989d4,
    name: "boxsConnects___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/boxsConnects.vue').default
  }, {
    path: "/zh-cn/castTest",
    component: _34416dd4,
    name: "castTest___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/castTest.vue').default
  }, {
    path: "/zh-cn/cloudPrintTest",
    component: _9f6db6e2,
    name: "cloudPrintTest___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/cloudPrintTest.vue').default
  }, {
    path: "/zh-cn/dashboard",
    component: _7a386b82,
    name: "dashboard___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/dashboard.vue').default
  }, {
    path: "/zh-cn/flasher",
    component: _cabdc6f0,
    name: "flasher___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/flasher.vue').default
  }, {
    path: "/zh-cn/login",
    component: _3714f4d2,
    name: "login___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/login/index.vue').default
  }, {
    path: "/zh-cn/logout",
    component: _5fa1ecef,
    name: "logout___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/logout.vue').default
  }, {
    path: "/zh-cn/octopusAlertTest",
    component: _5ae22bd8,
    name: "octopusAlertTest___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/octopusAlertTest.vue').default
  }, {
    path: "/zh-cn/octopusTest",
    component: _26b156fc,
    name: "octopusTest___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/octopusTest.vue').default
  }, {
    path: "/zh-cn/profile",
    component: _31040717,
    name: "profile___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/index.vue').default
  }, {
    path: "/zh-cn/settings",
    component: _14434070,
    name: "settings___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/settings.vue').default
  }, {
    path: "/zh-cn/uberEats",
    component: _c1d20646,
    name: "uberEats___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/uberEats/index.vue').default
  }, {
    path: "/en/profile/changePassword",
    component: _1d591576,
    name: "profile-changePassword___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changePassword.vue').default
  }, {
    path: "/en/profile/changeProfile",
    component: _4c728d9e,
    name: "profile-changeProfile___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changeProfile.vue').default
  }, {
    path: "/zh-cn/profile/changePassword",
    component: _1d591576,
    name: "profile-changePassword___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changePassword.vue').default
  }, {
    path: "/zh-cn/profile/changeProfile",
    component: _4c728d9e,
    name: "profile-changeProfile___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/profile/changeProfile.vue').default
  }, {
    path: "/en/posDevices/remote/:id?",
    component: _0d73e002,
    name: "posDevices-remote-id___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/posDevices/remote/_id.vue').default
  }, {
    path: "/zh-cn/posDevices/remote/:id?",
    component: _0d73e002,
    name: "posDevices-remote-id___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/posDevices/remote/_id.vue').default
  }, {
    path: "/posDevices/remote/:id?",
    component: _0d73e002,
    name: "posDevices-remote-id___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/posDevices/remote/_id.vue').default
  }, {
    path: "/en/*",
    component: _58d8c58a,
    name: "all___en",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/_.vue').default
  }, {
    path: "/zh-cn/*",
    component: _58d8c58a,
    name: "all___zh-cn",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/_.vue').default
  }, {
    path: "/",
    component: _05b98a06,
    name: "index___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/index.vue').default
  }, {
    path: "/*",
    component: _58d8c58a,
    name: "all___zh-hk",
    page: require('../dep/feathers-client/vue-extract-loader/index.js?block=page!../pages/_.vue').default
  }],

  parseQuery: function(q) {
      return require("qs").parse(q);
    },
  stringifyQuery: function(q) {
      var r = require("qs").stringify(q);
      return r ? "?" + r : "";
    },
  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  router.routes = routerOptions.routes;

  return router
}
