
import moment from 'moment'

export default function ({ app, store, route, params, error, redirect }) {
    if(route.query.lang) {
        store.commit('SET_LANG', route.query.lang)
    }
    app.i18n.locale = store.state.locale
    moment.locale(store.getters.mlocaleCode);
}
