<template>
    <v-app ref="app" style="width: 100%; height: 100%;">
        <v-snackbar top v-model="error">
            {{ errorMessage }}
            <v-btn text color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
        </v-snackbar>

        <v-content style="height:100%;">
            <v-layout fill-height :column="breakpoint.mdAndDown">
                <v-snackbar top v-model="error">
                    {{ errorMessage }}
                    <v-btn text color="pink" @click.native="error = false" v-t="'basic.close'"></v-btn>
                </v-snackbar>

                <v-layout
                    xs4
                    lg6
                    class="loginGradient flex"
                    justify-center
                    align-center
                    fill-height
                    :column="!breakpoint.mdAndDown"
                >
                    <div class="white--text display-1">歡迎回來</div>
                    <!-- <nuxt-link to="login">
                        <img class="applogo" src="~/assets/images/logo2.png"/>
                    </nuxt-link>BOXS
                    <div class="app-name"></div>-->
                </v-layout>

                <v-layout
                    xs8
                    lg6
                    justify-center
                    align-center
                    fill-height
                    class="loginSide flex pa-2"
                >
                    <transition name="fade" mode="out-in">
                        <nuxt></nuxt>
                    </transition>
                </v-layout>
            </v-layout>
        </v-content>
    </v-app>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
import uuid from "uuid/v4";

export default {
    data() {
        return {
            isHydrated: false,
        };
    },
    head() {
        return {
            htmlAttrs: {
                style: this.breakpoint.mdAndDown
                    ? "overflow-y: auto;"
                    : "overflow: hidden; height: 100%;",
            },
            bodyAttrs: {
                style: "overflow: hidden; height: 100%;",
            },
        };
    },
    computed: {
        error: {
            get() {
                return !!this.$store.state.error;
            },
            set(v) {
                if (!v) {
                    this.$store.commit("SET_ERROR");
                }
            },
        },

        breakpoint() {
            // just an example, could be one specific value if that's all you need
            return this.isHydrated
                ? this.$vuetify.breakpoint
                : {
                      mdAndDown: false,
                  }; // "empty" $breakpoint object with initial values
        },

        errorMessage() {
            return this.$store.state.error;
        },
    },
    components: {},
    async mounted() {
        this.isHydrated = true;
        document.addEventListener("gesturestart", this.gesture);
    },
    beforeDestroy() {
        document.removeEventListener("gesturestart", this.gesture);
    },
    methods: {
        gesture(e) {
            e.preventDefault();
        },
    },
};
</script>


<style scoped>
button {
    border: 1px solid black;
}

.loginGradient {
    background: linear-gradient(to bottom, #ffa8a8, #ffb593);
    width: 100%;
}

.loginSide {
    background: white;
    width: 100%;
}

.loginSide .v-card {
    border: none;
}

.applogo {
    max-width: 200px;
}

.app-name {
    padding-left: 8px;
    color: white;
    font-size: 64px;
    font-weight: 900;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
}
</style>
