import { LabelSequence } from "pos-printer/labelSequence";
import { init } from "pos-printer";
import { Vue } from "@feathers-client";

export async function print(
  root: Vue,
  provisionParams: {
    service_name_str: string;
    service_key_str: string;
    pop_str: string;
  },
  kid: string,
  provisionCode: string,
) {
  try {
    const server = await init(root);
    const printer = await server.getQueue("label", true);
    const sequence = await printer.createSequence<LabelSequence>();
    sequence.reset();
    sequence.size(40, 30);
    sequence.qrcode(provisionCode, 10, 10, "M", 4);
    // sequence.text(provisionParams.service_name_str, 20, 180);
    // sequence.text(kid, 20, 200, 140, undefined, 2);

    // sequence.text(
    //     provisionParams.service_key_str,
    //     200,
    //     20,
    //     40,
    //     undefined,
    //     2
    // );
    // sequence.text(provisionParams.pop_str, 200, 60, 40, undefined, 2);

    await sequence.printImage(240, 10, require("~/assets/images/logo_cropped.png"), 50, "alpha");
    sequence.rotate(270);
    await sequence.text("BOXS IOT", 230, 220, 60, undefined, 4, "2");
    await sequence.text(provisionParams.service_name_str.slice(14), 270, 220, 60, undefined, 4, "2");

    sequence.print(1);

    const job = await sequence.getJob();
    await printer.print(job);
  } catch (e) {
    console.warn(e);
  }
}
