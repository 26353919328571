
import { Component, Prop, Vue, Watch, mixins, FindType, Ref } from "@feathers-client";
import { PrintSequence } from "pos-printer/printSequence";
import { init } from "pos-printer";
import { EscPosPrintSequence } from "pos-printer/printSequence/escpos";
import { print } from "~/plugins/label";
import { DebugPrinter } from "@feathers-client/../pos-printer/printers/debug";
import "xterm/css/xterm.css";
import { Terminal } from "xterm";
import { checkID } from "@feathers-client";
import moment from "moment";

@Component
export default class extends Vue {
  @Prop()
  item: FindType<"boxsConnects">;

  serialEnabled = false;

  @Ref()
  container: HTMLElement;
  terminal: Terminal;

  async beforeDestroy() {
    if (this.terminal) {
      this.terminal.dispose();
      this.terminal = null;
    }
    if (this.serialEnabled) {
      await this.$feathers.service("boxsConnects/unsubscribe").create({
        id: this.item._id,
      });
    }
  }

  get provisionParams() {
    try {
      const jwkParsed = this.item.publicKey;
      if (!jwkParsed) return null;
      const iot_key = Buffer.from(jwkParsed.x, "base64");
      let service_name_str = `PROV_BOXS_IOT_${iot_key.slice(0, 4).toString("hex").toUpperCase()}`;
      let service_key_str = iot_key.slice(8, 16).toString("hex");
      let pop_str = iot_key.slice(16, 24).toString("hex");
      return {
        service_key_str,
        pop_str,
        service_name_str,
      };
    } catch (e) {
      return null;
    }
  }

  get provisionCode() {
    if (!this.provisionParams) return "";
    return `{\"ver\":\"v1\",\"name\":\"${this.provisionParams.service_name_str}\",\"password\":\"${this.provisionParams.service_key_str}\",\"pop\":\"${this.provisionParams.pop_str}\",\"transport\":\"softap\",\"kid\":\"${this.item.kid}\"}`;
  }

  async print() {
    await print(this, this.provisionParams, this.item.kid, this.provisionCode);
  }

  async test() {
    const server = await init(this.$root);
    const printer = new DebugPrinter(server, {
      port: "debug",
    });
    const sequence = new EscPosPrintSequence(this as any, printer);

    sequence.text("Testing");
    sequence.text("中文測試");
    sequence.text("テスト");
    sequence.text("테스트");

    sequence.text("UTF8: ");
    sequence.raw([0xe4, 0xb8, 0xad, 0xe6, 0x96, 0x87, 0xe6, 0xb8, 0xac, 0xe8, 0xa9, 0xa6]);
    sequence.text("");

    sequence.text("BIG5: ");
    sequence.raw([0xa4, 0xa4, 0xa4, 0xe5, 0xb4, 0xfa, 0xb8, 0xd5]);
    sequence.text("");

    sequence.text("GB18030: ");
    sequence.raw([0xd6, 0xd0, 0xce, 0xc4, 0x9c, 0x79, 0xd4, 0x87]);
    sequence.text("");

    sequence.text("GBK: ");
    sequence.raw([0xd6, 0xd0, 0xce, 0xc4, 0x9c, 0x79, 0xd4, 0x87]);
    sequence.text("");

    sequence.text("euc-jp: ");
    sequence.raw([0xc3, 0xe6, 0xca, 0xb8, 0xc2, 0xac, 0xbb, 0xee]);
    sequence.text("");

    sequence.text("iso-2022-jp: ");
    sequence.raw([0x1b, 0x24, 0x42, 0x43, 0x66, 0x4a, 0x38, 0x42, 0x2c, 0x3b, 0x6e, 0x1b, 0x28, 0x42]);
    sequence.text("");

    sequence.text("shift_jis: ");
    sequence.raw([0x92, 0x86, 0x95, 0xb6, 0x91, 0xaa, 0x8e, 0x8e]);
    sequence.text("");

    sequence.text("euc-kr: ");
    sequence.raw([0xf1, 0xe9, 0xd9, 0xfe, 0xf6, 0xb4, 0xe3, 0xcb]);
    sequence.text("");

    sequence.fontSize(1, 1).text("Large");
    sequence.reset().fontSize();
    sequence.center().text("Center");
    sequence.right().text("Right");
    sequence.reset();
    sequence.fill("-");
    sequence.printQR("testing", 24);
    sequence.feed(10);
    sequence.cut();
    sequence.feed(1);
    const job = sequence.getJob("Test");

    await this.$feathers.service("printerJobs").create({
      jobName: "Test Print",
      type: "boxs",
      kid: this.item.kid,
      content: job.data,
      expires: moment().add(15, "minutes").toDate(),
      status: "pending",
    });
  }

  async probe() {
    for (let id of [1, 2, 3, 33, 36, 96, 110, 65, 66, 67, 68, 69, 111, 112]) {
      await this.$feathers.service("printerJobs").create({
        jobName: "Probe",
        type: "boxs",
        kid: this.item.kid,
        // GS I
        content: Buffer.from([0x1d, 0x49, id]),
        expires: moment().add(15, "minutes").toDate(),
        status: "pending",
        probe: true,
      });
    }
  }

  async locate() {
    await this.$feathers.service("boxsCmds").create({
      kid: this.item.kid,
      cmdType: "locate",
      args: [],
    });
  }

  async ota() {
    await this.$feathers.service("boxsCmds").create({
      kid: this.item.kid,
      cmdType: "ota",
      args: [],
    });
  }

  async restart() {
    await this.$feathers.service("boxsCmds").create({
      kid: this.item.kid,
      cmdType: "command",
      args: ["restart"],
    });
  }

  cmdBuf: string[] = [];

  async startSerial() {
    await this.$feathers.service("boxsCmds").create({
      kid: this.item.kid,
      cmdType: "command",
      args: ["redirect_stdout"],
    });
    if (!this.serialEnabled) {
      this.serialEnabled = true;
      await Vue.nextTick();

      await this.$feathers.service("boxsConnects/subscribe").create({
        id: this.item._id,
      });

      this.terminal = new Terminal({
        convertEol: true,
      });
      this.terminal.onData(data => {
        for (let c of data) {
          if (c === "\x7F") {
            // backspace
            if (this.cmdBuf.length) {
              this.cmdBuf.pop();
              this.terminal.write("\b \b");
            }
          } else {
            if (c === "\n" || c === "\r") {
              const line = this.cmdBuf.join("");
              this.cmdBuf = [];
              this.$feathers.service("boxsCmds").create({
                kid: this.item.kid,
                cmdType: "command",
                args: [line],
              });
              this.terminal.write("\n");
            } else {
              this.cmdBuf.push(c);
              this.terminal.write(c);
            }
          }
        }
      });
      this.terminal.open(this.container);
    }
  }

  onStdOut(data) {
    if (!checkID(data, this.item)) return;
    if (this.terminal) {
      this.terminal.write(data.data);
    }
  }
}
