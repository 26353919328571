<template>
  <v-app
    v-action.fullscreen="fullscreen"
    :light="!dark"
    :dark="dark"
    ref="app"
    style="width: 100%"
    @touchmove.native="canMove($event)"
    :class="{ 'fill-height': fullPage }"
    v-event.openDialog="openDialog"
    v-event.modalResult="modalResult"
  >
    <status-host />
    <dialog-host>
      <template #item="{ item }">
        <teleport-menu
          :key="item.key"
          v-model="item.show"
          v-bind="item.modal"
          dialog
          overlay
          overlayClass="bg-black bg-opacity-20"
        >
          <template #default>
            <template v-if="item.loading">
              <v-card>
                <v-card-text style="text-align: center">
                  <v-progress-circular indeterminate color="blue"></v-progress-circular>
                </v-card-text>
              </v-card>
            </template>
            <template v-else>
              <component :is="item.component" v-bind="item.props" :modalId="item.key" />
            </template>
          </template>
        </teleport-menu>
      </template>
    </dialog-host>

    <boxs-app-container
      :fullPage="fullPage"
      :padding="!$store.state.fullscreen && !$store.state.tableMode && !$store.state.noPadding"
    >
      <template #appbar>
        <div
          class="h-18 d-flex items-center px-6 top_bar gap-x-2 bg-surface-default"
          v-if="!$store.state.fullscreen && !$store.state.customHeader"
        >
          <v-btn
            text
            icon
            @click.stop="$root.$emit('toggleDrawer')"
            class="pa-0"
            v-show="$breakpoint.lgAndDown || $store.state.tableMode"
          >
            <v-icon>$menu</v-icon>
          </v-btn>

          <v-toolbar-title class="pa-0">
            <boxs-nav-title />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-fade-transition v-for="action in $store.state.actions" :key="action.action">
            <b-btn
              v-if="!action.hide"
              :alt-icon="action.badge"
              :alt-text="action.altText ? $td(action.altText) : undefined"
              :loading="action.action === $store.state.processing"
              :to="action.to ? action.to : undefined"
              :disabled="!!$store.state.processing"
              @click.prevent.stop="action.action && $root.$emit(action.action, action)"
              :badge="action.badge"
              :badge-color="action.badgeColor"
              :badge-offset-x="18"
              :badge-offset-y="18"
            >
              <v-icon>{{ action.icon }}</v-icon>
            </b-btn>
          </v-fade-transition>
        </div>
      </template>

      <template #nav>
        <boxs-nav-bar
          :temporary="$store.state.fullscreen || $store.state.tableMode"
          :items="items"
          :mini.sync="mini"
          v-model="drawer"
        >
          <template #shopSwitch>
            <img
              src="~/assets/images/logo.png"
              width="40"
              class="rounded-lg"
              style="filter: drop-shadow(0px 2px 8px rgba(150, 134, 190, 0.16))"
            />
          </template>

          <template #bottom>
            <nuxt-link text class="shop-name-card menu-item d-flex mx-5 gap-x-3" :to="'/profile'">
              <div class="user-icon" v-if="$store.state.user && $store.state.user._id">
                <span>{{
                  $td($store.state.user.name || $store.state.user.email || "")
                    .split(" ")
                    .map(it => it[0])
                    .slice(0, 2)
                    .join("")
                }}</span>
              </div>
            </nuxt-link>
            <div
              class="bottom-card menu-item transition-all my-4"
              :style="$store.state.locale == 'en' ? 'flex-direction: column;' : ''"
            >
              <v-menu absolute offset-y v-model="lang">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon size="20" color="black">language</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in $store.state.locales"
                    :key="item.id"
                    :value="$store.state.locale === item.id"
                    @click="
                      $store.dispatch('changeLang', {
                        id: item.id,
                        $i18n,
                      })
                    "
                  >
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </boxs-nav-bar>
      </template>

      <template #default>
        <transition name="fade" mode="out-in">
          <nuxt></nuxt>
        </transition>
      </template>
    </boxs-app-container>
  </v-app>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
import uuid from "uuid/v4";

export default {
  data() {
    return {
      drawer: null,
      mini: false,
      lang: false,
      dialogs: [],
      schemas: null,
    };
  },
  head() {
    return {
      htmlAttrs: {
        style: this.fullPage ? `overflow: hidden; height: 100%` : "overflow: auto;",
      },
      bodyAttrs: {
        style: this.fullPage ? `overflow: hidden; height: 100%` : "",
      },
    };
  },
  computed: {
    fullPage() {
      return this.$store.state.fullPage;
    },
    mfullscreen() {
      return this.$store.state.fullscreen;
    },
    items() {
      return this.$store.getters["userId"]
        ? [
            {
              href: "/dashboard",
              title: "pages.home",
              action: "home",
              exact: true,
            },
            {
              href: "/profile",
              title: "pages.profile.$",
              action: "supervised_user_circle",
              exact: false,
            },
            ...this.$schemas.pageList,
            ...(this.$store.getters["auth/isAdmin"]
              ? [
                  {
                    href: "/admins",
                    title: "pages.admins",
                    action: "fas fa-lock",
                  },
                  // new
                ]
              : []),
            {
              title: "pages.tools.$",
              items: [
                {
                  href: "/flasher",
                  title: "pages.flasher.$",
                  action: "fas fa-user",
                },
                {
                  href: "/castTest",
                  title: "pages.castTest.$",
                  action: "fas fa-user",
                },
                {
                  href: "/bluetoothTest",
                  title: "pages.bluetoothTest.$",
                  action: "fas fa-user",
                },
                {
                  href: "/octopusTest",
                  title: "pages.octopusTest.$",
                  action: "fas fa-user",
                },
                {
                  href: "/octopusAlertTest",
                  title: "pages.octopusAlertTest.$",
                  action: "fas fa-user",
                },
                {
                  href: "/cloudPrintTest",
                  title: "pages.cloudPrintTest.$",
                  action: "fas fa-user",
                },
              ],
            },
            {
              href: "/uberEats",
              title: "pages.uberEats.$",
              action: "fas fa-user",
            },
            {
              href: "/settings",
              title: "pages.settings.$",
              action: "fas fa-user",
            },
            {
              href: "/logout",
              title: "pages.logout",
              action: "exit_to_app",
            },
          ]
        : [
            {
              href: "/login",
              title: "pages.login",
              action: "vpn_key",
              exact: false,
            },
          ];
    },
    dark() {
      return this.$store.state.dark;
    },
    error: {
      get() {
        return !!this.$store.state.error;
      },
      set(v) {
        if (!v) {
          this.$store.commit("SET_ERROR");
        }
      },
    },

    errorMessage() {
      return this.$store.state.error;
    },
    ...mapGetters(["attributes", "role"]),
  },
  components: {},
  beforeMount() {
    this.$vuetify.theme.dark = this.dark;
    this.schemas = this.$schemas;
  },
  async mounted() {
    document.addEventListener("fullscreenchange", this.fullscreenChange);
    document.addEventListener("webkitFullscreenchange", this.fullscreenChange);
    document.addEventListener("gesturestart", this.gesture);

    this.updateFullPage();
    this.$schemas.init();
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.fullscreenChange);
    document.removeEventListener("webkitFullscreenchange", this.fullscreenChange);
    document.removeEventListener("gesturestart", this.gesture);
  },
  methods: {
    gesture(e) {
      e.preventDefault();
    },
    fullscreen() {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          this.$store.commit("SET_FULLSCREEN", false);
        }
        return;
      }
      if (document.webkitFullscreenElement) {
        if (document.webkitFullscreenElement) {
          document.webkitExitFullscreen();
          this.$store.commit("SET_FULLSCREEN", false);
        }
      }
      if (!window.navigator.standalone) {
        const elem = this.$refs.app.$el;
        const rfq =
          elem.requestFullscreen ||
          elem.webkitRequestFullscreen ||
          elem.mozRequestFullScreen ||
          elem.msRequestFullscreen;
        if (!rfq) return;
        rfq.call(elem);
      }
      this.$store.commit("SET_FULLSCREEN", true);
    },

    exitFullscreen() {
      if (document.fullscreenElement && document.exitFullscreen) document.exitFullscreen();
      if (document.webkitFullscreenElement && document.webkitExitFullscreen) document.webkitExitFullscreen();
      this.$store.commit("SET_FULLSCREEN", false);
    },

    fullscreenChange(event) {
      this.$store.commit("SET_FULLSCREEN", event.fullscreen);
    },

    startMove(event) {
      if (event.targetTouches.length === 1) {
        this._clientX = event.targetTouches[0].clientX;
        this._clientY = event.targetTouches[0].clientY;
      }
    },

    canMove(event) {
      if (this.$store.state.fullPage) {
        event.stopPropagation();
        if (event.targetTouches.length === 1) {
          if (
            this.hasScrollable(
              event.target,
              event.targetTouches[0].clientX - this._clientX,
              event.targetTouches[0].clientY - this._clientY,
            )
          ) {
            this._scrolling = true;
          } else if (!this._scrolling) {
            event.preventDefault();
          }
        }
      }
    },

    hasScrollable(target, clientX, clientY) {
      if (target.classList.contains("scrollable") || target.classList.contains("v-data-table__wrapper")) {
        if (target.scrollHeight > target.clientHeight && Math.abs(clientY) > Math.abs(clientX)) {
          if (
            (target.scrollTop > 0 || clientY < 0) &&
            (target.scrollHeight - target.scrollTop > target.clientHeight || clientY > 0)
          ) {
            return true;
          }
        }
        if (target.scrollWidth > target.clientWidth && Math.abs(clientX) > Math.abs(clientY)) {
          if (
            (target.scrollLeft > 0 || clientX < 0) &&
            (target.scrollWidth - target.scrollLeft > target.clientWidth || clientX > 0)
          ) {
            return true;
          }
        }
      }
      return target.parentElement && this.hasScrollable(target.parentElement, clientX, clientY);
    },

    endMove(event) {
      if (event.targetTouches.length === 0) {
        this._scrolling = false;
      }
    },

    updateFullPage() {
      if (process.server) return;
      const nuxt = document.getElementById("__nuxt");
      if (this.fullPage) nuxt.classList.add("fill-height");
      else nuxt.classList.remove("fill-height");

      const layout = document.getElementById("__layout");
      if (this.fullPage) layout.classList.add("fill-height");
      else layout.classList.remove("fill-height");
    },

    toggleDrawer() {
      if (this.drawer) {
        this.mini = !this.mini;
      } else {
        this.drawer = true;
        this.mini = false;
      }
    },

    openDialog(e) {
      let item;
      const hide = (result, err) => {
        if (!item._show) return;
        item._show = false;

        if (err) {
          e.reject(err);
        } else {
          e.resolve(result);
        }

        setTimeout(() => {
          const idx = this.dialogs.indexOf(item);
          idx !== -1 && this.dialogs.splice(idx, 1);
        }, 500);
      };
      item = {
        key: uuid(),
        props: e.props,
        loading: false,
        _show: true,
        get show() {
          return this._show;
        },
        set show(v) {
          if (!v) {
            hide();
          }
        },
        hide,
      };
      if (e.component.then) {
        item.loading = true;
        item.modal = { maxWidth: "100px", persistent: true };
        e.component
          .then(c => {
            item.loading = false;
            item.modal = e.modal;
            item.component = c.default || c;
          })
          .catch(e => {
            console.warn(e);
            hide(undefined, e);
          });
      } else {
        item.component = e.component;
        item.modal = e.modal;
      }
      this.dialogs.push(item);
    },

    modalResult(e) {
      const item = this.dialogs.find(it => it.key === e.id);
      if (item) {
        item.hide(e.result);
      }
    },
  },
  watch: {
    fullPage(val) {
      this.updateFullPage();
    },
    mfullscreen(val) {
      if (val) this.drawer = false;
    },
    drawer(val) {
      if (val && this.mfullscreen) {
        Vue.nextTick(() => {
          this.drawer = false;
        });
      }
    },
    dark(val) {
      this.$vuetify.theme.dark = val;
      if (val) {
        this.mini = true;
      } else {
        this.mini = false;
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter {
  opacity: 0;
  transform: translateY(30px);
}

.fade-leave-active {
  opacity: 0;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.v-applicaiton {
  width: "100%";
}

.root-full-page,
.root-full-page body,
.root-full-page #__nuxt,
.root-full-page #__layout,
.root-full-page #app {
  height: 100%;
  overflow: hidden;
}

.root-full-page .v-application--wrap {
  min-height: initial;
}
</style>

<style scoped>
.v-toolbar {
  transition: 0.5s all;
}

.v-list__tile__action {
  justify-content: center;
  min-width: 28px;
  margin-right: 16px;
}

.v-list__tile__content ~ .v-list__tile__action:not(.v-list__tile__action--stack) {
  margin-right: 0px;
  margin-left: 16px;
  justify-content: center;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-grow: 1;
}

.v-navigation-drawer >>> .v-navigation-drawer__content {
  overflow: hidden;
}

.top_bar {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
  height: calc(constant(safe-area-inset-top) + 72px) !important;
  height: calc(env(safe-area-inset-top) + 72px) !important;
  /* background: linear-gradient(to bottom, #1183a1, #094554);  */
}

.theme--dark .top_bar {
  @apply bg-table-bg;
}
</style>

<style>
.ios-pad-top {
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}

.ios-pad-bottom {
  padding-bottom: constant(safe-area-inset-bottom) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}
</style>

<style lang="scss" scoped>
.user-icon {
  width: 40px;
  height: 40px;
  background: #828282;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
}

.shop-name {
  text-transform: initial;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.shop-name-card {
  display: flex;
  color: var(--v-grey500-base);
  align-items: center;
}
</style>
