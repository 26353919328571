<template>
    <v-dialog v-model="inputValue" max-width="80%" content-class="mediaLibrary">
      <v-card
        height="100%"
        @dragenter="dragenter"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        :class="{ dragging: dragging }"
      >
        <div class="d-flex h-full flex-col">
          <v-card-text class="flex-grow h-full relative">
            <div class="absolute inset-0 d-flex">
              <div
                class="mr-1 overflow-hidden attachList h-full basis-full md:basis-1/2"
              >
                <data-table
                  ref="table"
                  :data="{
                    path: '/attachments',
                    filter: {
                      $sort: { date: -1 },
                      ...mimeFilter,
                    },
                  }"
                  iterator
                  content-tag="div"
                  content-class="ma-0 flex flex-wrap"
                  :paginate="40"
                  fill-height
                >
                  <template slot="item" slot-scope="props">
                    <div
                      style="width: 150px"
                      v-ripple
                      @click="pickItem(props.item)"
                      :class="[
                        {
                          selected: selectedItems.indexOf(props.item._id) !== -1,
                        },
                        'attachmentItem',
                      ]"
                    >
                      <v-img
                        flat
                        tile
                        style="cursor: pointer"
                        :src="$thumb(props.item) || getExt(props.item.mime)"
                        contain
                        :height="150"
                        :width="150"
                        gradient="transparent 65%, rgba(34,34,34, 0.8) 65%, rgba(34,34,34, 1) 95%"
                      >
                        <div style="width: 150px" class="flex flex-col h-full">
                          <div class="flex content-center justify-center">
                            <v-progress-circular
                              color="primary"
                              :value="props.item.progress * 100"
                              v-if="props.item.uploading"
                            />
                          </div>
                          <div
                            v-if="props.item.error"
                            class="flex content-center justify-center"
                          >
                            <v-icon color="red">error</v-icon>
                          </div>
                          <v-spacer />
                          <div class="pos-item">{{ $td(props.item.name) }}</div>
                        </div>
                      </v-img>
                    </div>
                  </template>
  
                  <template slot="post-actions">
                    <v-btn icon @click="addFile"><v-icon>add</v-icon></v-btn>
                  </template>
                </data-table>
              </div>
  
              <div class="ml-1 overflow-hidden h-full basis-full md:basis-1/2">
                <v-card height="100%">
                  <div class="d-flex flex-col h-full">
                    <v-card-title v-t="'mediaLibrary.details'" />
                    <v-card-text class="flex-grow overflow-hidden basis-0">
                      <div class="scrollable h-full overflow-y-auto">
                        <template v-if="curItem">
                          <v-img :src="$thumb(curItem)" :height="150" contain />
                          <v-list>
                            <v-list-item
                              v-for="(header, idx) in headers"
                              :key="idx"
                            >
                              <v-list-item-content>
                                <v-list-item-title v-text="header.title" />
                                <v-list-item-subtitle
                                  v-ripple
                                  @click="copyTextToClipboard(header.value)"
                                  v-text="header.value"
                                />
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </template>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <template v-if="curItem">
                        <v-btn icon @click="deleteImage(curItem)"
                          ><v-icon>delete</v-icon></v-btn
                        >
                      </template>
                    </v-card-actions>
                  </div>
                </v-card>
              </div>
            </div>
          </v-card-text>
          <v-card-actions v-if="selecting">
            <v-row justify="space-around" no-gutters>
              <v-col cols="auto">
                <v-btn
                  :loading="loading"
                  large
                  color="green"
                  @click.prevent.stop="save"
                >
                  <v-icon>done</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  large
                  color="red"
                  @click.prevent.stop="(selectedItems = []), (inputValue = false)"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import moment from "moment";
  import Vue from "vue";
  import uuid from "uuid/v4";
  import { copyTextToClipboard } from "@feathers-client/util";
  
  function regEscape(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, (c) => {
      switch (c) {
        case "*":
          return ".*";
        case ",":
          return "|";
        default:
          return `\\${c}`;
      }
    });
  }
  
  export default {
    computed: {
      inputValue: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit("input", v);
        },
      },
  
      mimeFilter() {
        if (!this.type || this.type === "*" || this.type === "*/*") return {};
        return {
          mime: {
            $regex: `^(${regEscape(this.type)})`,
          },
        };
      },
  
      headers() {
        if (!this.curItem) return;
  
        return [
          {
            title: this.$t("mediaLibrary.id"),
            value: this.curItem._id,
          },
          {
            title: this.$t("mediaLibrary.name"),
            value: this.curItem.name,
          },
          {
            title: this.$t("mediaLibrary.date"),
            value: moment(this.curItem.date).format("lll"),
          },
          {
            title: this.$t("mediaLibrary.path"),
            value: this.curItem.path,
          },
          {
            title: this.$t("mediaLibrary.src"),
            value: this.curItem.src,
          },
          {
            title: this.$t("mediaLibrary.mime"),
            value: this.curItem.mime,
          },
          {
            title: this.$t("mediaLibrary.size"),
            value: this.curItem.size,
          },
          ...(this.curItem.width
            ? [
                {
                  title: this.$t("mediaLibrary.width"),
                  value: this.curItem.width,
                },
                {
                  title: this.$t("mediaLibrary.height"),
                  value: this.curItem.height,
                },
              ]
            : []),
          ...(this.curItem.duration
            ? [
                {
                  title: this.$t("mediaLibrary.duration"),
                  value: this.curItem.duration,
                },
              ]
            : []),
        ];
      },
    },
  
    props: {
      value: { type: Boolean, default: false },
      parent: { type: String, default: "" },
      dir: { type: String, default: "" },
      type: { type: String, default: "image/*" },
      multiple: { type: Boolean },
      selecting: { type: Boolean },
    },
  
    methods: {
      copyTextToClipboard,
      getExt(mime) {
        switch (mime) {
          case "audio/mpeg":
          case "audio/mp3":
            return require("~/assets/file-icons/mp3.png");
          case "audio/aac":
            return require("~/assets/file-icons/aac.png");
          case "audio/wav":
            return require("~/assets/file-icons/wav.png");
  
          case "video/x-msvideo":
          case "video/mpeg":
          case "video/mp4":
            return require("~/assets/file-icons/mov.png");
  
          case "application/zip":
          case "application/x-7z-compressed":
          case "application/x-rar-compressed":
            return require("~/assets/file-icons/zip.png");
  
          case "application/msword":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return require("~/assets/file-icons/doc.png");
  
          case "application/pdf":
            return require("~/assets/file-icons/pdf.png");
  
          case "text/html":
            return require("~/assets/file-icons/html.png");
          default:
            return require("~/assets/file-icons/file.png");
        }
      },
  
      dragenter(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.types.includes("Files")) {
          if (!this.dragging) {
            this.dragging = true;
            this.$emit("beginDrag");
          }
          e.dataTransfer.dropEffect = "copy";
          return;
        }
        if (this.dragging) {
          this.dragging = false;
          this.$emit("endDrag");
        }
        e.dataTransfer.dropEffect = "none";
      },
      dragover(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.types.includes("Files")) {
          if (!this.dragging) {
            this.dragging = true;
            this.$emit("beginDrag");
          }
          e.dataTransfer.dropEffect = "copy";
          return;
        }
        if (this.dragging) {
          this.dragging = false;
          this.$emit("endDrag");
        }
        e.dataTransfer.dropEffect = "none";
      },
      async drop(e) {
        if (!this.dragging) return;
        e.preventDefault();
        e.stopPropagation();
        this.dragging = false;
        if (e.dataTransfer.types.includes("Files")) {
          const imgs = _.filter(e.dataTransfer.files, (file) => true);
          await Promise.all(imgs.map((img) => this.uploadFile(img)));
        }
        this.$emit("endDrag");
      },
      dragleave(e) {
        if (!this.dragging) return;
        e.preventDefault();
        e.stopPropagation();
        this.dragging = false;
        this.$emit("endDrag");
      },
  
      async uploadFile(mfile) {
        const info = {
          _id: uuid(),
          progress: 0,
          error: "",
          uploading: true,
          name: mfile.name,
          size: mfile.size,
          mime: mfile.type,
        };
  
        if (this.$refs.table) {
          if (!this.$refs.table.mitems.length) {
            this.$refs.table.source.total = 1;
          }
          this.$refs.table.mitems.unshift(info);
        }
  
        var data = new FormData();
        data.append("file", mfile, mfile.name);
  
        try {
          const response = await this.$feathers.post(
            `attachments/upload/${this.dir || "others"}/${
              this.parent || "others"
            }`,
            data,
            {
              onUploadProgress: (progressEvent) => {
                info.progress = progressEvent.loaded / progressEvent.total;
              },
            },
          );
  
          const rinfo = (response.data || {}).info || {};
          _.each(rinfo, (v, k) => Vue.set(info, k, v));
          info.uploading = false;
          info.progress = 1;
        } catch (e) {
          info.error = e.message;
          info.uploading = false;
        }
      },
  
      pickItem(item) {
        if (item.uploading || item.error) return;
        if (this.multiple) {
          const idx = this.selectedItems.indexOf(item._id);
          if (idx === -1) {
            this.curItem = item;
            this.selectedItems.push(item._id);
          } else {
            this.curItem = null;
            this.selectedItems.splice(idx, 1);
          }
        } else {
          this.selectedItems = [item._id];
          this.curItem = item;
        }
      },
  
      async deleteImage(item) {
        const c = await this.$openDialog(
          import("@feathers-client/components-internal/ConfirmDialog.vue"),
          {
            title: this.$t("basic.doYouWantToDelete"),
          },
          {
            maxWidth: "400px",
          },
        );
        if (!c) return;
        await this.$refs.table.deleteItemCore(item);
      },
  
      async addFile() {
        const file = document.createElement("input");
        file.style.display = "none";
        file.type = "file";
        file.accept = this.type;
        file.multiple = true;
        document.body.append(file);
        file.click();
        await new Promise((resolve) => (file.onchange = resolve));
        if (file.files.length == 0) return;
        await Promise.all(_.map(file.files, (img) => this.uploadFile(img)));
      },
  
      async save() {
        if (!this.selectedItems.length) {
          this.inputValue = false;
          return;
        }
        this.loading = true;
        try {
          const chunks = _.chunk(this.selectedItems, 20);
          const resps = await Promise.all(
            _.map(chunks, (chunk) =>
              this.$feathers.service("attachments").find({
                query: {
                  _id: {
                    $in: chunk,
                  },
                  $limit: 100,
                },
              }),
            ),
          );
          const results = _.flatten(_.map(resps, (r) => r.data));
          if (!results.length) {
            this.inputValue = false;
            return;
          }
          this.$emit("selected", results);
        } catch (e) {
          this.$store.commit("SET_ERROR", e.message);
        } finally {
          this.loading = false;
        }
      },
    },
  
    data() {
      return {
        curItem: null,
        dragging: false,
        loading: false,
        selectedItems: [],
      };
    },
  };
  </script>
  
  <style scoped>
  .pos-item {
    color: white;
    padding: 3px;
    height: 3.5em;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
  .scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
  }
  
  .dragging {
    background: lightblue;
  }
  
  .attachList >>> .attachmentItem.selected .v-image:after {
    content: "";
    border: 4px solid #3388ff;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  </style>
  
  <style>
  .mediaLibrary {
    height: 90%;
    overflow: hidden;
  }
  </style>
  