
import { Component, Prop, Vue, Watch, mixins } from "nuxt-property-decorator";
import { VueMonacoEditor } from "./Editor";

// @ts-ignore
@Component({
    components: {
        VueMonacoEditor,
    },
})
export default class JsonEditor extends Vue {
    // @ts-ignore
    @Prop({ type: Boolean })
    isObject: Boolean;

    // @ts-ignore
    @Prop(Boolean)
    readonly: boolean;

    // @ts-ignore
    @Prop()
    value: any;

    @Prop({ type: String, default: "json" })
    language: string;

    @Prop()
    label: string;

    cachedStr: string = null;
    hasCache = false;

    error: string = null;

    get inputValue() {
        if (this.language !== "json") return this.value;
        try {
            return this.isObject
                ? this.cachedStr ||
                      JSON.stringify(this.value || {}, undefined, "  ")
                : this.value;
        } catch (e) {}
        return {};
    }

    set inputValue(v) {
        if (this.readonly) return;
        if (this.language !== "json") {
            this.$emit("input", v);
            return;
        }
        try {
            if (this.isObject) {
                this.hasCache = true;
                this.cachedStr = v;
            }
            this.$emit("input", this.isObject ? JSON.parse(v) : v);
            if(!this.isObject) {
              JSON.parse(v);
            }
            this.error = "";
        } catch (e) {
          this.error = e.message;
        }
    }

    @Watch("inputValue")
    onInputValueChange() {
        if (this.isObject) {
            if (this.hasCache) {
                this.hasCache = false;
            } else {
                this.cachedStr = null;
            }
        }
    }

    formatJson() {
        this.inputValue = JSON.stringify(JSON.parse(this.inputValue), null, 2);
    }
}
